@import '@/services/scss/_mixin';
@import '@/services/scss/_variables';

.switch-vendor-modal {
    .from-to {
        @include flexCenter;
        gap: 10px;
        padding: 32px 0 16px;
        .from,
        .to {
            width: 48px;
            height: 48px;
            border: 1px solid $ltGreyScale300;
            border-radius: 6px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .switch-vendor-content {
        text-align: center;
        padding: 16px;
        span {
            font-weight: 600;
        }
    }
    .switch-vendor-actions {
        @include itemsCenter;
        gap: 10px;
        padding: 16px;
        border-top: 1px solid $ltGreyScale300;
        button {
            width: 100%;
            padding: 10px 12px;
            border-radius: 6px;
            border: 1px solid;
            font-weight: 600;
            &:first-child {
                border-color: $ltGreyScale300;
                background: none;
            }
            &:last-child {
                background: $ltPrimary;
                border-color: $ltPrimary;
                color: $white;
            }
        }
    }
}
