@import '@/services/scss/_variables';

.patients-list {
    color: $ltTitle;
    font-weight: 500;
    max-height: 200px;
    overflow: auto;
    .remove-test {
        color: $ltError;
        &:hover {
            color: $ltError !important;
            background: #fff0f0 !important;
        }
    }
    .active {
        color: $ltPrimary !important;
        background: #0e76730d !important;
    }
    div {
        padding: 10px 16px;
        &:hover {
            background: #0e76730d;
            color: $ltPrimary;
        }
        &:not(:last-child) {
            border-bottom: 1px solid $ltGreyScale300;
        }
    }
}

/* 
            
        .spinner-wrapper {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;

*/
