@import '@/services/scss/_mixin';
@import '@/services/scss/_variables';

.pricing-list,
.pricing-grid {
    .regular-price {
        font-size: 10px;
        text-decoration: line-through;
    }
    .discount-price {
        font-weight: 600;
        color: $ltTitle;
        @include ltBodyTypo(14px, 22.4px);
    }
}

.pricing-list {
    @include itemsCenter;
    gap: 5px;
}

.pricing-grid {
    .discount-wrapper,
    .discount-wrapper-reversed {
        gap: 10px;
        @include itemsCenter;
        color: #1010104d;
    }
    .discount-wrapper-reversed {
        justify-content: flex-end;
    }
}
