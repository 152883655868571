@import '@/services/scss/_variables';

.tests-list-top {
    top: 0;
    gap: 10px;
    display: flex;
    position: sticky;
    padding: 0 0 16px;
    background: $white;
    align-items: center;
    .icon {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(29, 159, 153, 0.1);
        border-radius: 100%;
        img {
            width: 24px;
            height: 24px;
        }
    }
    div {
        padding: 0;
    }
}
.test-item {
    gap: 10px;
    display: flex;
    font-size: 12px;
    line-height: 19.2px;
    align-items: center;
    &:not(:last-child) {
        margin: 0 0 10px;
    }
    .icon {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            display: block;
        }
    }
}