@import "@/services/scss/variables.scss";
@import "@/services/scss/mixin.scss";

$maxHgt: 80vh;

.modal {
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    // overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: flex-end;
    color: $dark;
    @include flexCenter;
    // @include transition;

    .overlay {
        background-color: transparent;
        width: 100%;
        height: 100%;
    }

    .modalContent {
        background-color: $white;
        width: 100%;
        max-width: $maxWdt;
        min-height: 25vh;
        border-radius: 15px 15px 0px 0px;
        position: fixed;
        max-height: $maxHgt;
        top: auto;
        bottom: 0;
        padding-top: 15px;
        z-index: 99999;
        animation: slideIn 0.1s ease-out;

        .title {
            border-bottom: 1px solid rgba($color: $greyD, $alpha: 0.3);
            margin: 0;
            padding: 0 $cp 15px $cp;
            padding-bottom: 10px;
            font-size: 14px;
            font-weight: bold;
            @include flexBetween;
            align-items: center;

            .title_with_close {
                @include flexBetween;
                align-items: center;
                width: 100%;
            }

            svg {
                @include itemsCenter;
            }
        }
        .with_subtitle {
            flex-direction: column;
            align-items: flex-start;

            span {
                font-size: 14px;
                font-weight: 400;
                color: $greyD;
                margin-top: 5px;
            }
        }
        .child {
            padding: 0 $cp 15px $cp;
            max-height: $maxHgt - 4;
            overflow-y: scroll;
            @include scrollBarTransparent;
        }
        .close {
            position: absolute;
            top: -50px;
            @include flexCenter;
            width: 100%;

            span {
                font-size: 20px;
                color: $white;
                background-color: $dark;
                width: 40px;
                height: 40px;
                @include flexCenter;
                border-radius: 100%;
            }
        }
    }
    // .modal_transition {
    //     margin-bottom: 0px;
    // }
    @keyframes slideIn {
        from {
            margin-bottom: -150px;
        }

        to {
            margin-bottom: 0;
        }
    }
    .transparent {
        background-color: transparent;
    }
}

.center_modal {
    .modalContent {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include borderRadius;
        width: 95%;
        animation: slideIn 0.1s ease-out;

        .child {
            padding: 0;
        }

        @keyframes slideIn {
            from {
                margin-top: 90vh;
            }

            to {
                margin-top: 0;
            }
        }
    }
}

.custom_style {
    .modalContent {
        .child {
            padding: 0;
        }
    }
}

.bottom_modal {
    .modalContent {
        bottom: 100px;
        border-radius: 15px;

        .close {
            top: auto;
            bottom: -50px;
        }
    }
}

.modal_absolute {
    position: absolute !important;
    height: 90% !important;
}

.close_top {
    position: absolute;
    // top: -50px;
    bottom: 230px;
    @include flexCenter;
    width: 100%;

    span {
        font-size: 20px;
        color: $white;
        background-color: $dark;
        width: 40px;
        height: 40px;
        @include flexCenter;
        border-radius: 100%;
    }
}
