@import "@/services/scss/variables.scss";
@import "@/services/scss/mixin.scss";

// .modal {
//     position: fixed;
//     z-index: 9999;
//     left: 0;
//     top: 0;
//     width: 100%;
//     height: 100%;
//     overflow: auto;
//     background-color: rgba(0, 0, 0, 0.5);
//     display: flex;
//     align-items: flex-end;
//     color: $dark;

//     .modalContent {
//         background-color: #fff;
//         width: 100%;
//         border-radius: 24px 24px 0px 0px;
//         position: fixed;
//         height: auto;
//         top: auto;
//         bottom: 0;

//         .close {
//             position: absolute;
//             top: -50px;
//             @include flexCenter;
//             width: 100%;

//             span {
//                 font-size: 20px;
//                 color: $white;
//                 background-color: $dark;
//                 width: 40px;
//                 height: 40px;
//                 @include flexCenter;
//                 border-radius: 100%;
//             }
//         }
//     }
// }

.content_login {
    // padding: 15px $cp;

    h3 {
        font-weight: 600;
        font-size: 14px;
    }

    p {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;

        a {
            color: $primary;
            font-weight: 600;
        }
    }

    .p_color {
        color: $greyD;
        a {
            text-decoration: underline;
        }
    }
    .verify_number {
        font-size: 12px;
        font-weight: 400;
        margin: 0;
        @include itemsCenter;
        white-space: nowrap;

        b {
            font-size: 14px;
            font-weight: 600;
            color: $primary;
            margin-left: 5px;
        }
        span {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
        }
    }
    .resend_code {
        p {
            margin: 0;
            color: $dark;
            padding: 15px 0 0 0;
            text-align: center;
        }
        button {
            background-color: transparent;
            color: $primary;
            font-size: 12px;
            font-weight: 600;
            span {
                color: $grey300;
                font-weight: normal;

                .timer {
                    color: $primary;
                }
            }
        }
    }

    .reset {
        @include flexBetween;

        button {
            width: 80px;
            padding: 10px 0;
            margin-top: 10px;
            background-color: $dark;

            &:last-child {
                background-color: $error;
            }
        }
    }

    label {
        font-size: 12px;
        border: 1px solid $grey300;
        font-weight: 500;
        // padding: 15px;
        @include borderRadius;
        align-items: center;
        width: 100%;
        display: flex;
        margin-top: 10px;
        padding: 0 10px;

        &:first-child {
            margin-top: 0;
        }

        input {
            border: none;
            padding: 15px 5px;
            width: 100%;
            background-color: transparent;
            outline: none;
            border: none;

            &:focus {
                outline: none;
                border: none;
                background-color: transparent;
            }
        }
        ::placeholder {
            color: $grey300;
        }
    }
    .disable {
        background-color: $grey200;
        color: $greyD;

        input {
            color: $greyD;
        }
    }

    button {
        display: block;
        width: 100%;
        background-color: $primary;
        color: $white;
        border: none;
        padding: 15px 0;
        @include borderRadius;
        font-weight: 600;
    }
    .btn_mrg_top {
        margin-top: 15px;
    }

    .ref {
        font-weight: 600;
        font-size: 12px;
        color: $primary;
        margin: 5px 0;
        padding: 15px 0 0 0;
        display: block;
        text-align: center;
    }
}

.ios_label_input {
    font-size: 16px;
    label,
    input {
        font-size: 16px;
    }
}

.social_login_wrapper {
    .top {
        margin: 0;
        font-size: 10px;
        font-weight: 400;
        width: 100%;
        @include flexCenter;
        height: 25px;

        span {
            margin: 0 5px;
        }
        hr {
            outline: none;
            border: none;
            overflow: hidden;
            background-color: $grey300;
            width: 100%;
            height: 1px;
            margin-top: 7px;
            display: inline-block;
        }
    }

    .login_buttons {
        @include flexCenter;
        flex-wrap: wrap;
        width: 100%;
        margin: 15px 0 20px 0;

        div {
            margin: 0 10px;
            button {
                width: 45px;
                height: 45px;
                overflow: hidden;
                @include borderRadius;
                background-color: $white;
                border: 1px solid $grey200;
                @include flexCenter;
            }
        }
    }
}

.phone_email_input_wrapper {
    h3 {
        border-bottom: 1px solid $grey200;
        padding-bottom: 10px;
        font-weight: 600;
        font-size: 20px;
    }
    .content {
        span {
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 8px;
            display: block;
            sup {
                color: $error;
            }
        }
        label {
            @include itemsCenter;
            border: 1px solid $grey300;
            border-radius: 8px;
            font-size: 14px;
            font-weight: 400;
            padding-left: 10px;
            input {
                border: none;
                padding: 12px 5px;
                width: 100%;
                background-color: transparent;
                outline: none;
                border: none;

                &:focus {
                    outline: none;
                    border: none;
                    background-color: transparent;
                }
            }
            ::placeholder {
                color: $grey300;
            }
        }
        .with_error {
            border: 1px solid $error;
            color: $error;
            input {
                color: $error;
            }
        }
        .error {
            color: $error;
            font-size: 12px;
            font-weight: 400;
        }
        p {
            font-size: 12px;
            font-weight: 500;
            color: $grey500;
            button {
                background-color: transparent;
                border: none;
                font-size: 12px;
                font-weight: 500;
                text-decoration: underline;
                padding: 0;
                color: $primary;
            }
        }
    }
    .submit_button {
        background-color: $primary;
        padding: 12px;
        width: 100%;
        @include borderRadius;
        color: $white;
        border: none;
        margin-top: 10px;
    }
}

.phone_email_verify_wrapper {
    h3 {
        font-weight: 600;
        font-size: 20px;
    }
    .content {
        @include itemsCenter;
        justify-content: space-between;
        margin-bottom: 15px;
        padding-bottom: 12px;
        border-bottom: 1px solid $grey200;

        font-size: 14px;
        font-weight: 400;
        p {
            margin: 0;
            span {
                color: $primary;
            }
        }

        button {
            background-color: transparent;
            border: none;
            color: $primary;
            text-decoration: underline;
            font-size: 14px;
            font-weight: 400;
            padding: 0;
        }
    }

    .resend_code {
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        p {
            color: $grey300;
            margin: 0;
            padding: 15px 0 0 0;
            text-align: center;
            margin-bottom: 5px;
        }
        button {
            background-color: transparent;
            color: $primary;
            border: none;

            span {
                color: $grey300;
                font-weight: normal;

                .timer {
                    color: $primary;
                }
            }
        }
    }
    .submit_button {
        background-color: $primary;
        padding: 12px;
        width: 100%;
        @include borderRadius;
        color: $white;
        border: none;
        margin-top: 20px;
    }
}

.icon_warning {
    @include flexCenter;
    padding-top: 10px;
    span {
        @include flexCenter;
        border-radius: 100%;
        width: 58px;
        height: 58px;
        padding: 0;
        background-color: rgba($color: $warning, $alpha: 0.1);

        svg {
            @include flexCenter;
            width: 32px;
            height: 32px;
        }
    }
}
.warning_message {
    font-size: 12px;
    font-weight: 400;
    padding: 5px 20px;
    color: $greyD;
    text-align: center;
    b {
        font-weight: 500;
        color: $dark;
    }
}
