@import '@/services/scss/_variables';

.sort-by-modal {
    height: 100%;
    background: $white;
    border-radius: 15px 15px 0 0;
    &-top {
        height: 40px;
        display: flex;
        padding: 0 16px;
        font-size: 14px;
        font-weight: 600;
        align-items: center;
        line-height: 22.4px;
        border-bottom: 1px solid $ltGreyScale300;
        justify-content: space-between;
        .clear-btn {
            color: $ltError;
        }
    }
    &-bottom {
        height: 100%;
        padding: 16px;
        .sort-by-label {
            gap: 10px;
            display: flex;
            font-size: 12px;
            line-height: 19.2px;
            align-items: center;
            &:not(:last-child) {
                margin: 0 0 16px;
            }
            svg {
                display: block;
            }
        }
    }
}
