@import '@/services/scss/_variables';

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid $ltGreyScale400;
    border-bottom-color: $ltPrimary;
    border-radius: 50%;
    display: block;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
