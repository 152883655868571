@import '@/services/scss/_variables';

.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(18, 24, 38, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    .modal-content {
        width: calc(100% - 32px);
        padding: 35px;
        border-radius: 15px;
        background: $white;
        text-align: center;
        font-weight: 500;
        .icon {
            width: 170px;
            height: 85px;
            margin: 0 auto 20px;
            svg {
                width: 100%;
                height: 100%;
            }
        }
        h2 {
            font-size: 18px;
            line-height: 27px;
            font-weight: 600;
        }
        p {
            font-size: 14px;
            line-height: 22.4px;
        }
        button {
            background: $ltPrimary;
            color: $white;
            display: block;
            min-width: calc(100% - 150px);
            padding: 10px 30px;
            margin: 20px auto auto;
            font-size: 14px;
            line-height: 22.4px;
            border: none;
            border-radius: 10px;
        }
    }
}
