@import '@/services/scss/_mixin';
@import '@/services/scss/_variables';

.vendors-modal {
    overflow: auto;
    .title {
        border-bottom: 1px solid $ltGreyScale300;
        padding: 16px;
        position: sticky;
        top: 0;
        background: $white;
    }
    .vendors {
        padding: 16px 16px 85px;
        @include flexColumn;
        gap: 10px;
        .lab,
        .lab-active {
            border-radius: 6px;
            border: 1px solid $ltGreyScale300;
            @include ltBoxShadow;
            padding: 10px;
            @include itemsCenter;
            gap: 10px;
            .lab-info {
                width: 100%;
                @include itemsCenter;
                gap: 10px;
                .lab-image {
                    width: 48px;
                    height: 48px;
                    border: 1px solid $ltGreyScale300;
                    overflow: hidden;
                    border-radius: 6px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .lab-content {
                    width: calc(100% - 58px);
                    @include flexCenterBetween;
                    .lab-name {
                        @include ltBodyTypo(14px, 22.4px);
                        font-weight: 600;
                        color: $ltTitle;
                    }
                    .pricing-wrapper {
                        text-align: right;
                    }
                }
            }
        }
        .lab-active {
            border-color: $ltPrimary;
        }
    }
    .vendors-modal-action {
        position: fixed;
        bottom: 0;
        width: 100%;
        border: 1px solid $ltGreyScale300;
        background: $white;
        padding: 16px;
        button {
            padding: 10px 12px;
            border: none;
            width: 100%;
            border-radius: 6px;
            background: $ltPrimary;
            color: $white;
            font-weight: 600;
        }
    }
}
