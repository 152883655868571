@import "@/services/scss/variables.scss";
@import "@/services/scss/mixin.scss";

// .alert_toast {
//     position: fixed;
//     top: auto;
//     bottom: 100px;
//     z-index: 9999;
//     width: 100%;
//     padding: 0 $cp;
//     animation: fadeInOut 5s linear forwards;

//     p {
//         margin: 0;
//         background-color: red;
//         @include borderRadius;
//         padding: 10px;
//     }

//     @keyframes fadeInOut {
//         0% {
//             opacity: 1;
//             visibility: visible;
//         }
//         100% {
//             opacity: 0.5;
//             z-index: 1;
//             visibility: hidden;
//         }
//     }
// }

.content_design_modal {
    // top: auto !important;
    bottom: auto !important;
    padding: 0 !important;
    max-width: 260px !important;

    .content_child_design {
        // padding: 15px !important;
        height: 100% !important;
        background-color: $white;
        min-height: 150px;
        @include borderRadius;
    }
}

.default_style {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100% !important;
    min-height: 130px;
    position: relative;

    .close_btn {
        position: absolute;
        background-color: transparent;
        border: none;
        left: auto;
        right: 0;
        padding: 5px;
        svg {
            color: $greyD;
        }
    }

    p {
        font-size: 14px;
        font-weight: 600;
        margin: 0;
        text-align: center;
        padding: 0 10px;
        padding-top: 10px;
    }
    span {
        padding: 0 10px;
        padding-top: 10px;
        text-align: center;
        display: block;
        color: $greyD;
        font-size: 12px;
        font-weight: 400;
    }
    // .content_wrp {
    //     box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 5px inset;
    // }

    .img_wrap {
        // background-color: rgba($color: pink, $alpha:1);

        img {
            max-width: 150px;
        }
    }

    .button_list {
        @include itemsCenter;
        justify-content: flex-end;
        margin-top: 10px;
        padding: 0 10px 10px 10px;
        border-top: 1px solid $grey200;
        padding-top: 10px;

        button {
            min-width: 75px;
            background-color: $primary;
            color: $white;
            padding: 10px 15px;
            font-weight: 600;
            border: none;
            @include borderRadius;
            border: 1px solid $primary;
            width: 100%;
        }
        .cancel {
            background-color: $error;
            color: $white;
            border: 1px solid $error;
        }
        .transparent_btn {
            background-color: transparent;
            color: $dark;
            border: 1px solid $grey200;
        }
    }
}

.simple_content_design_modal{
    .content_child_design {
        min-height: 115px;
    }
}
.simple_style {
    display: flex;
    align-items: flex-start;
    min-height: 105px;
    p,
    span {
        text-align: left;
    }

    p {
        padding: 0 15px;
    }
    span {
        padding: 10px 15px 0 15px;
    }

    .button_list {
        height: 100%;
        border-top: none;
        padding: 10px 15px 0 15px;
        button {
            width: auto;
            background-color: transparent;
            color: $primary;
            border: none;
            padding: 0;
            display: flex;
            justify-content: flex-end;
        }
    }
}
