@import '@/services/scss/_variables';

.filter-by-modal {
    overflow: hidden;
    background: $white;
    border-radius: 15px 15px 0 0;
    &-top {
        height: 40px;
        display: flex;
        padding: 0 16px;
        font-size: 14px;
        font-weight: 600;
        align-items: center;
        line-height: 22.4px;
        border-bottom: 1px solid $ltGreyScale300;
        justify-content: space-between;
    }
    &-bottom {
        padding: 16px;
        overflow: auto;
        background: $white;
        max-height: calc(350px - 40px);
        .filter-by-label {
            gap: 10px;
            display: flex;
            font-size: 12px;
            line-height: 19.2px;
            align-items: center;
            &:not(:last-child) {
                margin: 0 0 16px;
            }
            svg {
                display: block;
            }
        }
    }
    .min-max {
        gap: 10px;
        display: flex;
        align-items: center;
        input {
            width: 90px;
            padding: 10px 16px;
            border-radius: 6px;
            border: 1px solid $ltGreyScale300;
            &:focus {
                outline: none;
            }
        }
    }
    .filter-by-actions {
        gap: 10px;
        display: flex;
        padding: 16px;
        border-top: 1px solid $ltGreyScale300;
        justify-content: space-between;
        button {
            width: 100%;
            padding: 10px;
            border: 1px solid;
            border-radius: 6px;
            &.clear-btn {
                color: $ltError;
                background: none;
                border-color: $ltError;
            }
            &.apply-btn {
                color: $white;
                background: $ltPrimary;
                border-color: $ltPrimary;
            }
        }
    }
}
