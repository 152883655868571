@import '@/services/scss/_mixin';
@import '@/services/scss/_variables';

.book-test-drawer {
    background: $white;
    display: grid;
    grid-template-rows: repeat(5, auto);
    .selected-test {
        padding: 16px;
        @include flexColumn;
        background: $ltGreyScale100;
        border-bottom: 1px solid $ltGreyScale300;
        .test-info {
            display: flex;
            gap: 10px;
            border-bottom: 1px solid $ltGreyScale300;
            padding: 0 0 16px;
            .test-image {
                width: 84px;
                height: 84px;
                border: 1px solid $ltGreyScale300;
                border-radius: 4px;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    display: block;
                    object-fit: cover;
                }
            }
            .test-content {
                @include flexColumn;
                @include ltBodyTypo(12px, 19.2px);
                justify-content: space-between;
                width: calc(100% - 94px);
                .test-name,
                .test-description {
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
                .test-name {
                    @include ltBodyTypo(14px, 22.4px);
                    font-weight: 600;
                    color: $ltTitle;
                }
                .included-test {
                    color: $ltPrimary;
                }
            }
        }
        .patient-selector {
            @include flexCenterBetween;
            padding: 16px 0 0;
            .label {
                @include ltBodyTypo(14px, 22.4px);
                font-weight: 600;
                color: $ltTitle;
            }
            .counter {
                @include itemsCenter;
                gap: 20px;
                button[type='button'] {
                    background: none;
                    border: none;
                    width: 32px;
                    height: 32px;
                    border-radius: 4px;
                    border: 1px solid $ltGreyScale300;
                    @include flexCenter;
                    &:last-child {
                        background: $ltPrimary;
                    }
                }
                .count {
                    color: $ltPrimary;
                    width: 77px;
                }
            }
        }
    }
    .warning-message-wrapper {
        padding: 10px 16px;
        .info-message {
            @include ltBodyTypo(12px, 19.2px);
            @include itemsCenter;
            gap: 10px;
            padding: 10px;
            border-radius: 6px;
            color: $ltTitle;
            .icon {
                svg {
                    display: block;
                }
            }
        }
        .info-message {
            background: #f5f8ff;
        }
    }
    .title {
        @include ltBodyTypo(14px, 22.4px);
        font-weight: 600;
        color: $ltTitle;
    }
    .selected-lab,
    .available-labs {
        .lab,
        .lab-active,
        .lab-inactive {
            border-radius: 6px;
            border: 1px solid $ltGreyScale300;
            @include ltBoxShadow;
            padding: 10px;
            @include itemsCenter;
            gap: 10px;
            .lab-info {
                width: 100%;
                @include itemsCenter;
                gap: 10px;
                .lab-image {
                    width: 48px;
                    height: 48px;
                    border: 1px solid $ltGreyScale300;
                    overflow: hidden;
                    border-radius: 6px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .lab-content {
                    width: calc(100% - 58px);
                    @include flexCenterBetween;
                    .lab-name-wrapper {
                        .lab-name {
                            @include ltBodyTypo(14px, 22.4px);
                            font-weight: 600;
                            color: $ltTitle;
                        }
                    }
                    .lab-name-wrapper-inactive {
                        @include ltBodyTypo(14px, 22.4px);
                        color: $grey500;
                        .inactive-label {
                            @include ltBodyTypo(12px, 19.2px);
                        }
                    }
                    .warning {
                        color: $grey500;
                    }
                    .pricing-wrapper {
                        text-align: right;
                        .inactive-price {
                            @include ltBodyTypo(14px, 22.4px);
                            color: $grey500;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
    .selected-lab,
    .available-labs {
        padding: 0 16px 20px;
        .lab-active {
            border-color: $ltPrimary;
        }
    }
    .available-labs {
        overflow: auto;
        .labs {
            @include flexColumn;
            gap: 10px;
        }
    }
    .drawer-action {
        padding: 16px;
        border-top: 1px solid $ltGreyScale300;
        background: $white;
        .action-button,
        .action-button-disabled {
            width: 100%;
            border: none;
            padding: 10px 12px;
            border-radius: 6px;
        }
        .action-button {
            background: $ltPrimary;
            color: $white;
        }
        .action-button-disabled {
            background: $grey200;
            color: $grey500;
        }
    }
}
